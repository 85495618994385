<template>
  <div class="text-start" style="padding: 0.4rem">
    <h5>{{ $store.getters.companyName }}</h5>
    <div class="setting-card" :class="[isLoading && 'opacity-50 pe-none']">
      <h4 class="text-center fw-normal">
        {{ $t("borrow.borrow_return_setting") }}
      </h4>
      <p>{{ $t("borrow.need_scanqr_function") }}</p>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          v-model="scanQR"
          :disabled="isLoading"
          :aria-describedby="ariaDescribedby"
          class="d-flex"
        >
          <b-form-radio class="radio-label me-2" :value="true"
            ><p class="mx-2">{{ $t("borrow.enable") }}</p></b-form-radio
          >
          <b-form-radio class="radio-label mx-2" :value="false"
            ><p class="mx-2">
              {{ $t("borrow.disable") }} {{ $t("borrow.default_value") }}
            </p></b-form-radio
          >
        </b-form-radio-group>
      </b-form-group>
      <p>{{ $t("borrow.1hr_borrow_space") }}</p>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          v-model="borrowGap"
          :disabled="isLoading"
          :aria-describedby="ariaDescribedby"
          class="d-flex"
        >
          <b-form-radio class="radio-label me-2" :value="true"
            ><p class="mx-2">{{ $t("borrow.enable") }}</p></b-form-radio
          >
          <b-form-radio class="radio-label mx-2" :value="false"
            ><p class="mx-2">
              {{ $t("borrow.disable") }} {{ $t("borrow.default_value") }}
            </p></b-form-radio
          >
        </b-form-radio-group>
      </b-form-group>
      <div class="d-flex justify-content-center align-center gap-3 mt-4">
        <button class="btn btn-cancel" @click.prevent="$router.back()">
          {{ $t("btn.back") }}
        </button>
        <button class="btn btn-submit" @click="updateBorrowReturnSettings">
          {{ $t("btn.save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { authHeader } from "../../store/actions";
import { borrowUrl } from "@/util/backend";

export default {
  data() {
    return {
      id_company: null,
      scanQR: false,
      borrowGap: false,
      showBorrower: false,
      isLoading: true,
    };
  },
  methods: {
    async updateBorrowReturnSettings() {
      try {
        await axios.post(
          `${borrowUrl()}setting/company/id/${this.id_company}`,
          {
            is_borrow_return_gap: this.borrowGap,
            is_borrow_return_scan: this.scanQR,
          },
          authHeader()
        );
        Swal.fire({
          icon: "success",
          title: this.$t("borrow.borrow_return_setting_updated"),
          showConfirmButton: false,
          timer: 2000,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async fetchBorrowReturnSettings() {
      try {
        const res = await axios(
          `${borrowUrl()}setting/company/id/${this.id_company}`,
          authHeader()
        );
        const data = res.data;
        this.borrowGap = data.is_borrow_return_gap;
        this.scanQR = data.is_borrow_return_scan;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
  async created() {
    const id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.id_company = id_company;
    await this.fetchBorrowReturnSettings();
  },
};
</script>

<style lang="scss" scoped>
.setting-card {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  max-width: 600px;
  padding: 2rem;
  margin: 20px auto;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.btn.btn-cancel {
  background: #7c7c7c;
}
.btn.btn-submit {
  background: #1a73e8;
}
</style>
